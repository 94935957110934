import { useForm } from "react-hook-form";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { AlertCircle, Upload } from "lucide-react";
import { ChangeEvent, useEffect, useState } from "react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useUpdateOrgProfile } from "@/services/profile";
import { toast } from "react-toastify";

// List of all US states
const US_STATES = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

const FormSchema = z.object({
  orgName: z.string().max(50, {
    message: "org name must be less than 50 characters.",
  }),
  department: z.string().max(20, {
    message: "department name must be less than 50 characters.",
  }),
  address1: z.string().max(50, {
    message: "address must be less than 50 characters.",
  }),
  address2: z.string().max(50, {
    message: "address must be less than 50 characters.",
  }),
  city: z.string().max(20, {
    message: "city name must be less than 20 characters.",
  }),
  state: z.string(),
  zip: z.string().max(10, {
    message: "zip code must be less than 10 characters.",
  }),
});

export const OrgProfileForm = () => {
  const { data: orgData } = useActiveOrgProfile();

  const orgName = orgData?.name || "";
  const department = orgData?.org?.profile_metadata?.department_name || "";
  const address1 = orgData?.org?.profile_metadata?.address1 || "";
  const address2 = orgData?.org?.profile_metadata?.address2 || "";
  const city = orgData?.org?.profile_metadata?.city || "";
  const state = orgData?.org?.profile_metadata?.state || "";
  const zip = orgData?.org?.profile_metadata?.zip_code || "";

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      orgName: "",
      department: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  useEffect(() => {
    if (orgData) {
      form.reset({
        orgName: orgData.org.name,
      });
      if (orgData.org.profile_metadata.avatar_preview_url) {
        setImgPreview(orgData.org.profile_metadata.avatar_preview_url);
      }
    }
  }, [orgData, form]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imgPreview, setImgPreview] = useState<string | ArrayBuffer | null>("");
  const [error, setError] = useState("");

  const { mutateAsync, isPending } = useUpdateOrgProfile({
    onSuccess: () => {
      toast.success("Organization profile updated successfully");
    },
    onError: () => {
      toast.error("Organization profile update failed");
    },
  });

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      setError("Please select a file");
      return;
    }

    const file = event.target.files[0];
    setError("");

    // Validate file type
    if (!file.type.startsWith("image/")) {
      setError("Please select an image file");
      return;
    }

    // Validate file size (500KB)
    if (file.size > 500 * 1024) {
      setError("File size must be less than 500KB");
      return;
    }

    setSelectedFile(file);

    // Create preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImgPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    const formData = new FormData();

    // Only append non-empty values to FormData
    formData.append("org_name", data.orgName || orgName);
    formData.append("department_name", data.department || department);
    formData.append("address1", data.address1 || address1);
    formData.append("address2", data.address2 || address2);
    formData.append("city", data.city || city);
    formData.append("state", data.state || state);
    formData.append("zip_code", data.zip || zip);
    formData.append("country", "US");

    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    await mutateAsync(formData);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8"
      >
        <Card className="border-primary/20">
          <CardContent className="px-14 py-12 flex flex-col gap-4">
            <div>
              <FormField
                control={form.control}
                name="orgName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Organization Name</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder={orgName}
                        className="w-full"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="grid gap-4">
              <div>
                <FormField
                  control={form.control}
                  name="department"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Department</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder={department}
                          className="w-full"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div>
              <FormField
                control={form.control}
                name="address1"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Address 1</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder={address1}
                        className="w-full"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="address2"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Address 2</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder={address2}
                        className="w-full"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <FormField
                  control={form.control}
                  name="city"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>City</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder={city}
                          className="w-full"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <FormField
                  control={form.control}
                  name="state"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>State</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={state}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select state" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {US_STATES.map((state) => (
                            <SelectItem key={state.value} value={state.value}>
                              {state.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <FormField
                  control={form.control}
                  name="zip"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Zip</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder={zip}
                          className="w-full"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="logo">Upload Organization Logo</Label>
              <div className="border-2 border-dashed rounded-lg p-4 text-center">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileSelect}
                  className="hidden"
                  id="file-upload"
                />
                <label htmlFor="file-upload" className="cursor-pointer">
                  {imgPreview ? (
                    <img
                      src={imgPreview as string}
                      alt="Preview"
                      className="max-h-48 mx-auto mb-2 rounded"
                    />
                  ) : (
                    <div className="flex flex-col items-center">
                      <Upload className="h-12 w-12 text-gray-400 mb-2" />
                      <span className="text-sm text-gray-500">
                        Click to upload (max 500KB)
                      </span>
                    </div>
                  )}
                </label>
              </div>
              {error && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
            </div>
          </CardContent>
        </Card>

        <Button type="submit" className="w-[150px] mx-auto">
          {isPending ? "Saving..." : "Save"}
        </Button>
      </form>
    </Form>
  );
};

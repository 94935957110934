import { STATUS_SUCCESS } from "@/constants/status";
import { APIFetch, useTokenAndOrgId } from "../utils";
import { useQuery } from "@tanstack/react-query";

interface OrgMetricData {
  server_instance_count: number;
  active_user_organization_count: number;
  storage_volume_count: number;
  total_storage_volume_size: number;
}

export const useGetOrgMetrics = () => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-metric", token, orgId],
    enabled: !!token && !!orgId,
    queryFn: async (): Promise<OrgMetricData> => {
      const result = await APIFetch.post("getOrgMetric", {
        token,
        body: { org_id: orgId },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error("Failed to load org metrics, please try again.");
      }

      const metricData = result?.data;
      return metricData;
    },
  });
};

type OrgGraphData = {
  server_graph_data: Array<{
    datetime: string;
    active_servers: number;
  }>;

  ServerUsageData: Array<{
    user_id: number;
    template_id: number;
    total_usage_time_hours: number;
  }>;
};

type OrgUsageGraphData = {
  items: Array<{
    date: string;
    total_cost: number;
  }>;
};

export type GetOrgGraphParams = {
  interval: "hour" | "day" | "week" | "month";
  start_at: string;
  end_at: string;
};

export const useGetOrgGraph = (params: GetOrgGraphParams, enabled: boolean = true) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-graph", token, orgId, params],
    enabled: !!token && !!orgId && enabled,
    queryFn: async (): Promise<OrgGraphData> => {
      const result = await APIFetch.post("getOrgGraph", {
        token,
        body: { org_id: orgId, ...params },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error("Failed to load org graph, please try again.");
      }

      const metricData = result?.data;
      return metricData;
    },
  });
};

type OrgUsersGraphData = {
  items: Array<{
    user: {
      user_id: number;
      email: string;
      profile_metadata: {
        first_name: string;
        last_name: string;
      };
    };
    total_usage: Array<{
      template_id: number;
      hour: number;
    }>;
  }>;
};

export const useGetOrgUsersGraph = (params: GetOrgGraphParams) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-users-graph", token, orgId, params],
    enabled: !!token && !!orgId,
    refetchInterval: false,
    queryFn: async (): Promise<OrgUsersGraphData> => {
      const result = await APIFetch.post("getOrgUserGraph", {
        token,
        body: { org_id: orgId, ...params },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error(
          "Failed to load org users graph data, please try again."
        );
      }

      const metricData = result?.data;
      return metricData;
    },
  });
};

export type AnalyticsData = {
  duration_seconds: number;
  spent: number;
  user_id: number;
  email: string;
  profile_metadata: {
    first_name: string;
    last_name: string;
    avatar_path?: string;
    avatar_preview_url?: string;
  };
};

type AnalyticsDataReturn = {
  items: Array<AnalyticsData>;
  total_duration_seconds: number;
  total_spent: number;
};

export const useGetOrgServerAnalytics = (
  params: Pick<GetOrgGraphParams, "start_at" | "end_at">
) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-server-analytics", token, orgId, params],
    enabled: !!token && !!orgId,
    refetchInterval: false,
    queryFn: async (): Promise<AnalyticsDataReturn> => {
      const result = await APIFetch.post("getOrgServerAnalytics", {
        token,
        body: { org_id: orgId, ...params },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error(
          "Failed to load org server analytics, please try again."
        );
      }

      const data = result?.data;
      return data;
    },
  });
};

export const useGetOrgStorageAnalytics = (
  params: Pick<GetOrgGraphParams, "start_at" | "end_at">
) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-storage-analytics", token, orgId, params],
    enabled: !!token && !!orgId,
    refetchInterval: false,
    queryFn: async (): Promise<AnalyticsDataReturn> => {
      const result = await APIFetch.post("getOrgStorageAnalytics", {
        token,
        body: { org_id: orgId, ...params },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error(
          "Failed to load org stoage analytics, please try again."
        );
      }

      const data = result?.data;
      return data;
    },
  });
};

export type GetOrgBalanceSummaryReturn = {
  total: number;
  spent_total: number;
  usage_percent: number;
};

export const useGetOrgBalanceSummary = () => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-balance-summary", token, orgId],
    enabled: !!token && !!orgId,
    refetchInterval: false,
    queryFn: async (): Promise<GetOrgBalanceSummaryReturn> => {
      const result = await APIFetch.post("getOrgBalanceSummary", {
        token,
        body: { org_id: orgId },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error(
          "Failed to load org balance summary, please try again."
        );
      }

      const data = result?.data;
      return data;
    },
  });
};

export type GetOrgSpendRateReturn = {
  server_cost: number;
  storage_cost: number;
  total_cost: number;
};

export const useGetOrgSpendRate = () => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-spend-rate", token, orgId],
    enabled: !!token && !!orgId,
    refetchInterval: false,
    queryFn: async (): Promise<GetOrgSpendRateReturn> => {
      const result = await APIFetch.post("getOrgSpendRate", {
        token,
        body: { org_id: orgId },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error("Failed to load org spent rate, please try again.");
      }

      const data = result?.data;
      return data;
    },
  });
};

export const useGetOrgUsageGraph = (params: GetOrgGraphParams, enabled: boolean = true) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-usage-graph", token, orgId, params],
    enabled: !!token && !!orgId && enabled,
    queryFn: async (): Promise<OrgUsageGraphData> => {
      const result = await APIFetch.post("getOrgUsageGraph", {
        token,
        body: { org_id: orgId, ...params },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error("Failed to load org usage graph, please try again.");
      }

      const metricData = result?.data;
      return metricData;
    },
  });
};

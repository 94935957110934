import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Search, Plus } from "lucide-react";
import { useNavigate } from "@tanstack/react-router";
import { useTokenAndOrgId } from "@/services/utils";
import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";
import { useCallback, useEffect, useState } from "react";
import { ServerViewSortKeys, ServerViewType } from "./types";
import { cn } from "@/lib/utils";
import { useDebounce } from "@/hooks/useDebounce";

type ManageStorageHeaderProps = {
  serverViewType: ServerViewType;
  onServerViewChange: (type: ServerViewType) => void;
  serverViewSortType: ServerViewSortKeys;
  onServerViewSortChange: (type: ServerViewSortKeys) => void;
  onSearchChange: (searchTerm: string) => void;
};

export const ManageServerHeader = ({
  serverViewType,
  onServerViewChange,
  serverViewSortType,
  onServerViewSortChange,
  onSearchChange,
}: ManageStorageHeaderProps) => {
  const navigate = useNavigate();
  const { orgId } = useTokenAndOrgId();
  const { data } = useActiveOrgProfile();
  const role = data?.role.role_name;

  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm);

  useEffect(() => {
    onSearchChange(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearchChange]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
    },
    [],
  );

  useEffect(() => {
    // making sure the tabs is set to 'My Storage' if role is user
    if (role === "User") {
      onServerViewChange(ServerViewType.MY_SERVER);
    }
  }, [role, onServerViewChange]);

  const handleCreateNewServer = () => {
    if (orgId) {
      navigate({ to: `/${orgId}/dashboard/servers/new` });
    } else {
      console.error("orgId is not available");
    }
  };

  return (
    <div className="w-full mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-medium">Running Instances</h1>
        <Button onClick={handleCreateNewServer}>
          <Plus className="w-4 h-4 mr-2" />
          New Server
        </Button>
      </div>

      <div className="flex items-center space-x-4 mb-6">
        <Tabs
          defaultValue={ServerViewType.MY_SERVER}
          className="w-[400px]"
          value={serverViewType}
          onValueChange={(val) => {
            onServerViewChange(val as unknown as ServerViewType);
          }}
        >
          <TabsList
            className={cn(
              "grid w-full grid-cols-2",
              role === "User" ? "hidden" : "",
            )}
          >
            <TabsTrigger value={ServerViewType.MY_SERVER}>
              My Servers
            </TabsTrigger>
            <TabsTrigger value={ServerViewType.USERS_SERVER}>
              User's Servers
            </TabsTrigger>
          </TabsList>
        </Tabs>

        <div className="relative flex-grow">
          <Search className="absolute w-4 h-4 left-3 top-1/2 transform -translate-y-1/2 text-foreground/40" />
          <Input
            type="text"
            placeholder="Search..."
            className="pl-10 w-full"
            value={searchTerm}
            onChange={handleSearchChange}
            maxLength={100}
          />
        </div>

        <Select
          defaultValue={ServerViewSortKeys.STARTED_AT_DESC}
          value={serverViewSortType}
          onValueChange={onServerViewSortChange}
        >
          <SelectTrigger className="w-[180px] border-0 shadow-none">
            <SelectValue placeholder="Sort by" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={ServerViewSortKeys.STARTED_AT_DESC}>
              Sort by <span className="text-primary">(Newest First)</span>
            </SelectItem>
            <SelectItem value={ServerViewSortKeys.STARTED_AT_ASC}>
              Sort by <span className="text-primary">(Oldest First)</span>
            </SelectItem>
            <SelectItem value={ServerViewSortKeys.INSTANCE_NAME_ASC}>
              Sort by <span className="text-primary">(A-Z)</span>
            </SelectItem>
            <SelectItem value={ServerViewSortKeys.INSTANCE_NAME_DESC}>
              Sort by <span className="text-primary">(Z-A)</span>
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

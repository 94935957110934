import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

import { useState } from "react";
import { useUpdateOrgUserRole } from "@/services/user-management";
import { toast } from "react-toastify";
import { useUserStore } from "@/store/UserStore";

type ChangeUserRoleProps = {
  userId: string;
  userEmail: string;
  role: string;
  disabled?: boolean;
};

export const ChangeUserRole = ({
  userId,
  userEmail,
  role,
  disabled,
}: ChangeUserRoleProps) => {
  const myEmail = useUserStore((state) => state.user?.email);

  const [value, setValue] = useState(role);
  const [openAlert, setOpenAlert] = useState(false);

  const [newRole, setNewRole] = useState("");

  const onRoleChange = (newRole: string) => {
    if (newRole === role) return;

    setNewRole(newRole);
    setOpenAlert(true);
  };

  const { mutate, isPending } = useUpdateOrgUserRole({
    onSuccess: () => {
      setOpenAlert(false);
      setValue(newRole);

      toast.success("User role changed successfully");
    },
    onError: () => {
      toast.error("Failed to change user role");
    },
  });

  const onConfirmChangeRole = () => {
    mutate({ userId: Number(userId), roleId: newRole === "Admin" ? 1 : 2 });
  };

  const isDisabled = myEmail === userEmail || disabled;

  return (
    <>
      <Select value={value} onValueChange={onRoleChange} disabled={isDisabled}>
        <SelectTrigger className="w-fit border-primary text-primary">
          <SelectValue placeholder="Role" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="Admin">Admin</SelectItem>
          <SelectItem value="User">User</SelectItem>
        </SelectContent>
      </Select>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Change user role?</AlertDialogTitle>
            <AlertDialogDescription>
              This action will change the role of user with email: "{userEmail}"
              to "{newRole}".
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={onConfirmChangeRole}
              disabled={isPending}
            >
              {isPending ? "Changing role..." : "Change role"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, MutProps, useTokenAndOrgId } from "../utils";

export const useUpdateUserProfile = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (formData: FormData) => {
      formData.append("org_id", String(orgId));

      const result = await fetch(`${API_URL}/updateOrgUserProfileV2`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      // invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ["get-org-user-profile"],
        exact: false,
      });

      return result.status;
    },
    ...props,
  });
};

export const useUpdateOrgProfile = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (formData: FormData) => {
      formData.append("org_id", String(orgId));

      const result = await fetch(`${API_URL}/updateOrgProfile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      // invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ["get-org-user-profile"],
        exact: false,
      });

      return result.status;
    },
    ...props,
  });
};

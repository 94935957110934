import {
  useGetOrgMetrics,
  useGetOrgSpendRate,
  useGetOrgUsageGraph,
} from "@/services/home";
import { DateRange } from "react-day-picker";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from "recharts";

import { Card, CardContent } from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { Spinner } from "@/components/spinner";
import { CalendarPopover } from "./calendar-popover";
import { useActiveOrgId } from "@/hooks/useActiveOrgId";
import { useGetOrgUserProfile } from "@/services/auth";
import { formatCurrency } from "@/lib/utils";
import { TopStats } from "./top-stats";

export const DashboardOverviewTab = () => {
  return (
    <>
      <TopStats />
      {/* <SecondaryStats /> */}
      <UsageChart />
    </>
  );
};

const SecondaryStats = () => {
  const { data } = useGetOrgMetrics();

  const displayedData = useMemo(() => {
    if (!data) return null;

    return [
      { label: "Total Storage Volume count", value: data.storage_volume_count },
      {
        label: "Total Storage volume size",
        value: data.total_storage_volume_size,
      },
      {
        label: "Total active users",
        value: data.active_user_organization_count,
      },
    ];
  }, [data]);

  return (
    <div className="mb-8 grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
      {displayedData?.map((item, index) => (
        <Card key={index} className="p-6 pb-4">
          <div className="mb-2 text-sm text-foreground/80">{item.label}</div>
          <div className="mb-2 text-2xl font-bold">{item.value}</div>
        </Card>
      ))}
    </div>
  );
};

const UsageChart = () => {
  const { orgId } = useActiveOrgId();
  const { data: org, isLoading: isOrgUserProfileLoading } =
    useGetOrgUserProfile(orgId);

  const [range, setRange] = useState<DateRange>({
    from: dayjs().subtract(1, "week").toDate(),
    to: dayjs().toDate(),
  });

  const totalDaysDiff = dayjs(range.to).diff(dayjs(range.from), "day");

  const { data: spentRate, isLoading: isOrgSpendRateLoading } =
    useGetOrgSpendRate();

  const { data, isLoading } = useGetOrgUsageGraph({
    interval: "day",
    start_at: range.from?.toISOString() || "",
    end_at: range.to?.toISOString() || "",
  });

  const chartData = useMemo(() => {
    if (!data?.items) return [];

    return data.items.map((item) => ({
      date: dayjs(item.date).format("D MMM"),
      "Total Cost": item.total_cost / 100,
      formatted_total_cost: formatCurrency(item.total_cost / 100),
    }));
  }, [data]);

  const formattedSpentRate = useMemo(() => {
    if (!spentRate) return 0;
    return formatCurrency(spentRate.total_cost / 100);
  }, [spentRate]);

  const formattedPeriodSpend = useMemo(() => {
    if (chartData.length === 0) return 0;

    const totalCost = chartData.reduce((acc, item) => acc + item["Total Cost"], 0);

    return formatCurrency(totalCost);
  }, [chartData]);

  const formattedRollingAverageRate = useMemo(() => {
    if (chartData.length === 0) return 0;

    const totalCost = chartData.reduce((acc, item) => acc + item["Total Cost"], 0);
    const averageCost = totalCost / chartData.length;

    return formatCurrency(averageCost);
  }, [chartData]);

  const chartConfig = {
    desktop: {
      label: "Desktop",
      color: "hsl(var(--chart-1))",
    },
  } satisfies ChartConfig;

  return (
    <>
      <div className="flex items-center mb-4 ml-2">
        <h3 className="text-xl font-semibold mr-12">Usage Spent</h3>
        <CalendarPopover
          range={range}
          onSelect={setRange}
          shouldDisable={(date) => {
            return isOrgUserProfileLoading
              ? true
              : dayjs(date).isBefore(dayjs(org?.org.created_at));
          }}
        />
      </div>

      <Card className="flex flex-col p-6 pl-0 justify-start min-h-[770px]">
        {isLoading ? (
          <Spinner />
        ) : (
          <CardContent className="mt-auto pl-0">
            <div className="flex flex-col gap-2 pl-8 mb-5">
              {isOrgSpendRateLoading ? (
                <Spinner />
              ) : (
                <>
                  <span>Period Spend</span>
                  <span className="">
                    <b className="text-2xl">{formattedPeriodSpend}</b>
                  </span>
                </>
              )}
            </div>
            <div className="flex flex-row gap-5 pl-8 mb-12">
              <div className="flex flex-col gap-2">
                {isOrgSpendRateLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <span>Rolling Average:</span>
                    <span className="">
                      <b className="text-2xl">{formattedRollingAverageRate}</b>{" "}
                      / day
                    </span>
                  </>
                )}
              </div>
              <div className="flex flex-col gap-2">
                {isOrgSpendRateLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <span>Current Spend Rate:</span>
                    <span className="">
                      <b className="text-2xl">{formattedSpentRate}</b> / hr
                    </span>
                  </>
                )}
              </div>
            </div>

            <ChartContainer config={chartConfig} className="max-h-[500px]">
              <BarChart
                accessibilityLayer
                data={chartData}
                margin={{
                  top: 20,
                }}
              >
                <CartesianGrid vertical={false} />
                <YAxis tickLine={false} tickMargin={10} axisLine={false} />
                <XAxis
                  dataKey="date"
                  tickLine={false}
                  tickMargin={10}
                  axisLine={false}
                  tickFormatter={(value) => value.slice(0, 6)}
                />
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent className="w-[150px]" />}
                />
                <Bar
                  dataKey="Total Cost"
                  fill="var(--color-desktop)"
                  radius={8}
                >
                  <LabelList
                    dataKey="formatted_total_cost"
                    className="fill-foreground"
                    content={data => {
                      const { x, y, height, value } = data;

                      const isOverflowing = totalDaysDiff > 10;

                      return (
                        <g>
                          <text
                            className="fill-foreground"
                            style={{ writingMode: isOverflowing ? 'vertical-rl' : 'horizontal-tb' }}
                            x={Number(x) + (isOverflowing ? 10 : 0)}
                            y={Number(y) + (isOverflowing ? 10 : -10) + (Number(height) < 60 && isOverflowing ? -60 : 0)}
                          >
                            {value}
                          </text>
                        </g>
                      );
                    }}
                  />
                </Bar>
              </BarChart>
            </ChartContainer>
          </CardContent>
        )}
      </Card>
    </>
  );
};

import "./index.css";
import "react-toastify/dist/ReactToastify.css";

import { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useUserStore } from "@/store/UserStore";
import Intercom from "@intercom/messenger-js-sdk";
import { ToastContainer } from "@/components/toastify";

const queryClient = new QueryClient();

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import ConsoleMasthead from "./lib/masthead";
import { NotFound } from "./pages/NotFound";
import { ThemeProvider } from "./components/theme-provider";
import * as Sentry from "@sentry/react";

ConsoleMasthead();

// Create a new router instance
const router = createRouter({
  routeTree,
  notFoundMode: "root",
  defaultNotFoundComponent: () => {
    return <NotFound />;
  },
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Component to initialize authentication on app load
export const MainApp = (): JSX.Element => {
  const user = useUserStore((state) => state.user);
  const posthog = usePostHog();

  useEffect(() => {
    if (user) {
      Intercom({
        app_id: "amez3ywz",
        user_id: user.email,
        name: user.email,
        email: user.email,
      });

      // identify the user for analytics
      posthog?.identify(user.email);
    }
  }, [user]);

  return <RouterProvider router={router} />;
};

const postHogOptions = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
};

if (process.env.SENTRY_ENABLED) {
  Sentry.init({
    dsn: "https://1852955d70fc5b4f781c199a82e6ef59@o4508163282567168.ingest.us.sentry.io/4508215825989632",
    integrations: [],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const PosthogWrapper = ({ children }: { children: React.ReactNode }) => {
  if (import.meta.env.PROD) {
    return (
      <PostHogProvider
        apiKey={import.meta.env.VITE_POSTHOG_KEY}
        options={postHogOptions}
      >
        {children}
      </PostHogProvider>

    )
  }

  return <>{children}</>;
}

// Render the app
const rootElement = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <StrictMode>
    <PosthogWrapper>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
          <ToastContainer />

          <MainApp />
        </ThemeProvider>
      </QueryClientProvider>
    </PosthogWrapper>
  </StrictMode>
);

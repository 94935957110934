import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { RemoveUserDialog } from "./remove-user";
import { UsersViewType } from "./types";

import dayjs from "dayjs";
import { ChangeUserRole } from "./change-user-role";
import Gravatar from "react-gravatar";

import { OrgUser } from "@/services/user-management";
import { useCreateOrgInvite } from "@/services/user-management";
import { toast } from "react-toastify";

type UsersTableProps = {
  usersView: UsersViewType;
  users: OrgUser[];
};

export const UserTable = ({ usersView, users }: UsersTableProps) => {
  const { mutateAsync: resendInvite, isPending: isResending } =
    useCreateOrgInvite({
      onSuccess: () => {
        toast.success("Invitation resent successfully");
      },
      onError: () => {
        toast.error("Failed to resend the invitation");
      },
    });

  const handleResendInvite = async (email: string, role: string) => {
    try {
      await resendInvite({ email, role });
    } catch (error) {
      console.error("Error resending invite:", error);
      toast.error("Failed to resend the invitation");
    }
  };

  if (!users.length) {
    return <div className="text-center text-foreground/60">No users found</div>;
  }

  return (
    <div className="w-full rounded-xl shadow-md overflow-hidden">
      <Table>
        <TableBody>
          {users.map((user) => {
            // Use expireDate to calculate expiration logic
            const isExpired = !user.expireDate || dayjs().isAfter(dayjs(user.expireDate));
            const timeLeft = user.expireDate
              ? dayjs(user.expireDate).diff(dayjs(), "days")
              : 0;
            return (
              <TableRow key={user.user_id} className="flex items-center">
                {/* Left: User Info */}
                <TableCell className="flex items-center space-x-4 py-4 flex-1">
                  {user.profile_metadata?.avatar_preview_url ? (
                    <img
                      src={user.profile_metadata.avatar_preview_url}
                      alt={`${user.profile_metadata?.first_name || ""} ${user.profile_metadata?.last_name || ""}'s avatar`}
                      className="h-10 w-10 rounded-full object-cover"
                    />
                  ) : (
                    <Gravatar
                      email={user.email}
                      className="h-10 w-10 rounded-full"
                    />
                  )}
                  <div>
                    <div className="font-semibold">
                      {user.profile_metadata?.first_name || "-"}{" "}
                      {user.profile_metadata?.last_name || "-"}
                    </div>
                    <div className="text-sm text-foreground/50">{user.email}</div>
                  </div>
                </TableCell>


                {/* Middle: Joined Date or Expiration Status */}
                {usersView === UsersViewType.PENDING ? (
                  <TableCell className="text-left flex-1">
                    {isExpired ? (
                      <span className="text-red-500">Expired</span>
                    ) : (
                      <span className="text-primary/70">
                        Expires in {timeLeft} {timeLeft === 1 ? "day" : "days"}
                      </span>
                    )}
                  </TableCell>
                ) : (
                  <TableCell className="text-left flex-1">
                    <span className="text-sm text-primary/50">
                      Joined date: {dayjs(user.joinedDate).format("MMMM DD, YYYY")}
                    </span>
                  </TableCell>
                )}

                {/* Right: Action Buttons */}
                <TableCell className="text-right flex-1">
                  <div className="flex items-center justify-end space-x-3">
                    {usersView === UsersViewType.PENDING && (
                      <button
                        className="bg-primary text-white px-4 py-2 rounded-md disabled:opacity-50"
                        onClick={() =>
                          handleResendInvite(user.email, user.role_name)
                        }
                        disabled={isResending}
                      >
                        {isResending ? "Re-sending..." : "Re-send"}
                      </button>
                    )}

                    <ChangeUserRole
                      disabled={usersView === UsersViewType.PENDING}
                      userId={user.user_id.toString()}
                      userEmail={user.email}
                      role={user.role_name}
                    />

                    <RemoveUserDialog
                      userId={user.user_id.toString()}
                      userEmail={user.email}
                      usersViewType={usersView}
                    />
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

import { useState } from "react";
import { UserManagementHeader } from "./header";
import { UsersViewType, UsersSortKeys } from "./types";
import { UserTable } from "./users-table";
import { useGetOrgUsers } from "@/services/user-management";
import { Spinner } from "@/components/spinner";
import { Virtuoso } from "react-virtuoso";

export const DashboardUserManagement = () => {
  const [usersView, onUsersViewChange] = useState(UsersViewType.EXISTING);
  const [searchTerm, setSearchTerm] = useState("");
  const [userSort, setUserSort] = useState(UsersSortKeys.CREATED_AT_DESC);

  // Determine the status based on the current tab
  const status = usersView === UsersViewType.EXISTING ? "active" : "invited";

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetOrgUsers(status, searchTerm, userSort);

  const allUsers = data?.allUsers || [];
  const total = data?.total || 0;

  return (
    <div className="flex flex-col gap-5">
      <UserManagementHeader
        usersViewType={usersView}
        onUsersViewChange={onUsersViewChange}
        onSearchChange={setSearchTerm}
        usersViewSortType={userSort}
        onUsersViewSortChange={setUserSort}
      />

      {/* Display totals for the selected tab */}
      {!isLoading && (
        <div className="text-left ml-4 my-2 text-sm text-foreground/75">
          Showing {allUsers.length} of {total} {status === "active" ? "active" : "invited"} users
        </div>
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <Virtuoso
          useWindowScroll
          data={allUsers}
          endReached={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          itemContent={(_index, user) => (
            <UserTable
              key={user.user_id}
              usersView={usersView}
              users={[user]}
            />
          )}
          components={{
            Footer: () => (
              <div className="text-center py-8">
                {isFetchingNextPage ? (
                  <Spinner />
                ) : hasNextPage ? null : (
                  <p className="text-sm text-foreground/50">End of list.</p>
                )}
              </div>
            ),
          }}
        />
      )}
    </div>
  );
};

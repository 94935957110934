import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { APIFetch, MutProps, useTokenAndOrgId } from "../utils";
import { ServerInstanceResponse } from "./types";
import {
  getServerViewSort,
  ServerViewSortKeys,
} from "@/pages/DashboardServers/types";

const LIST_SERVER_PAGE_SIZE = 10;

export const useListServerInstances = ({
  showAllUsers = false,
  sort = undefined,
  searchText = "",
  refetchInterval,
  staleTime,
}: {
  showAllUsers?: boolean;
  sort?: ServerViewSortKeys;
  searchText?: string;
  refetchInterval?: number;
  staleTime?: number;
}) => {
  const { orgId, token } = useTokenAndOrgId();

  const sortData = sort
    ? getServerViewSort(sort)
    : getServerViewSort(ServerViewSortKeys.STARTED_AT_DESC);

    const fetchServerInstances = async ({ pageParam = 1 }) => {
      const body = {
        org_id: orgId,
        page_number: pageParam,
        page_size: LIST_SERVER_PAGE_SIZE,
        show_all_users: showAllUsers,
        sort_direction: sortData.sortDirection,
        sort_by: sortData.sortBy,
        search_text: searchText,
      };
    
      try {
        const result = await APIFetch.post("listServerInstances", {
          token,
          body,
        });
        
        // Ensure instances is always an array, even if it's null
        const instances = result?.data?.instances ?? [];
        const total = result?.data?.total ?? 0;
    
        if (!Array.isArray(instances)) {
          console.error("Invalid API Response Format:", result);
          throw new Error("Invalid response from server");
        }
    
        // Map over instances if they exist
        const processedInstances = instances.map((instance: ServerInstanceResponse) => ({
          ...instance,
          user_metadata: instance.user_metadata
            ? {
                first_name: instance.user_metadata.first_name,
                last_name: instance.user_metadata.last_name,
              }
            : undefined,
        }));
    
        return {
          instances: processedInstances,
          total,
        };
      } catch (error) {
        console.error("Error fetching server instances:", error);
        return {
          instances: [],
          total: 0,
        };
      }
    };

  return useInfiniteQuery({
    queryKey: [
      "list-server-instances",
      orgId,
      token,
      showAllUsers,
      sort,
      searchText,
    ],
    queryFn: fetchServerInstances,
    enabled: !!orgId && !!token,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage || !lastPage.instances) {
        return undefined;
      }
  
      return lastPage.instances.length === LIST_SERVER_PAGE_SIZE
        ? pages.length + 1
        : undefined;
    },
    select: (data) => {
      const allInstances = data.pages.flatMap(page => page.instances);
      const total = data.pages[0]?.total ?? 0;
  
      return {
        pages: data.pages,
        total,
        allInstances,
      };
    },
    refetchInterval,
    staleTime,
  });
  
};

export const useGetServerInstance = (instanceId: number, enabled: boolean) => {
  const { orgId, token } = useTokenAndOrgId();

  return useQuery<ServerInstanceResponse>({
    queryKey: ["get-server-instance", instanceId, orgId, token],
    enabled: enabled && !!orgId && !!token && !!instanceId,
    queryFn: async () => {
      const body = {
        instance_id: instanceId,
        org_id: orgId,
      };
      const result = await APIFetch.post("getServerInstance", {
        token,
        body,
      });

      return result.data;
    },
  });
};

export const useCreateServerInstance = (
  props?: MutProps,
): UseMutationResult<
  unknown,
  Error,
  {
    instanceName: string;
    templateId: number;
    storageVolumeId?: number;
    allowSSH: boolean;
    extraPorts: number[];
  }
> => {
  const { token, orgId } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const result = await APIFetch.post("createServerInstance", {
        token,
        body: {
          org_id: orgId,
          instance_name: data.instanceName,
          template_id: data.templateId,
          ...(data.storageVolumeId && {
            storage_volume_id: data.storageVolumeId,
          }),
          allow_ssh: data.allowSSH,
          extra_ports: data.extraPorts,
        },
      });

      queryClient.invalidateQueries({
        queryKey: ["list-server-instances"],
        exact: false,
      });

      return result.status;
    },
    ...props,
  });
};

export const useDeleteServerInstance = (props?: MutProps) => {
  const { token, orgId } = useTokenAndOrgId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (instanceId: number) => {
      const result = await APIFetch.post("deleteServerInstance", {
        token,
        body: {
          org_id: orgId,
          instance_id: instanceId,
        },
      });

      queryClient.invalidateQueries({
        queryKey: ["list-server-instances"],
        exact: false,
      });

      return result.status;
    },
    ...props,
  });
};

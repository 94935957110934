export enum UsersViewType {
  EXISTING = "existing-users",
  PENDING = "pending-users",
}

export enum UsersSortKeys {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
}

// Sort configurations for Users
export const UsersSortBy = {
  [UsersSortKeys.CREATED_AT_ASC]: {
    sortBy: "created_at",
    sortDirection: "asc",
  },
  [UsersSortKeys.CREATED_AT_DESC]: {
    sortBy: "created_at",
    sortDirection: "desc",
  },
  [UsersSortKeys.FIRST_NAME_ASC]: {
    sortBy: "first_name",
    sortDirection: "asc",
  },
  [UsersSortKeys.FIRST_NAME_DESC]: {
    sortBy: "first_name",
    sortDirection: "desc",
  },
} as const;

export function getUserSort(key: UsersSortKeys) {
  return UsersSortBy[key];
}

export interface CSVRow {
  email: string;
  type: string;
}

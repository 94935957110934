import { useMemo } from "react";
import { Card } from "@/components/ui/card";
import {
  GetOrgGraphParams,
  useGetOrgGraph,
  useGetOrgMetrics,
  useGetOrgUsageGraph,
} from "@/services/home";
import { Activity, DollarSign, Timer } from "lucide-react";
import dayjs from "dayjs";
import { formatCurrency } from "@/lib/utils";

const last24hrs: GetOrgGraphParams = {
  start_at: dayjs().subtract(1, "day").toISOString(),
  end_at: dayjs().toISOString(),
  interval: "day",
};

const first24hrs: GetOrgGraphParams = {
  start_at: dayjs().subtract(2, "day").toISOString(),
  end_at: dayjs().subtract(1, "day").toISOString(),
  interval: "day",
};

export const TopStats = () => {
  const { data: orgMetric, isLoading: isOrgMetricLoading } = useGetOrgMetrics();

  const { data: orgUsageGraphLast24, isLoading: isOrgUsageGraphLoadingLast24 } =
    useGetOrgUsageGraph(last24hrs);
  const { data: orgGraphLast24, isLoading: isOrgGraphLoadingLast24 } =
    useGetOrgGraph(last24hrs);

  const {
    data: orgUsageGraphFirst24,
    isLoading: isOrgUsageGraphLoadingFirst24,
  } = useGetOrgUsageGraph(first24hrs);
  const { data: orgGraphFirst24, isLoading: isOrgGraphLoadingFirst24 } =
    useGetOrgGraph(first24hrs);

  const displayedData = useMemo(() => {
    const spendingLast24 =
      (orgUsageGraphLast24?.items || []).reduce(
        (acc, item) => acc + item.total_cost,
        0
      ) || 0;
    const hoursLast24 =
      (orgGraphLast24?.server_graph_data || []).reduce(
        (acc, item) => acc + item.active_servers,
        0
      ) || 0;

    const spendingFirst24 =
      (orgUsageGraphFirst24?.items || []).reduce(
        (acc, item) => acc + item.total_cost,
        0
      ) || 0;
    const hoursFirst24 =
      (orgGraphFirst24?.server_graph_data || []).reduce(
        (acc, item) => acc + item.active_servers,
        0
      ) || 0;

    const spendingDelta = spendingFirst24 === 0
      ? null
      : ((spendingLast24 - spendingFirst24) / spendingFirst24) * 100;

    const hoursDelta = hoursFirst24 === 0
      ? null
      : ((hoursLast24 - hoursFirst24) / hoursFirst24) * 100;

    return [
      {
        label: "Last 24 hours' spending",
        value: formatCurrency(spendingLast24 / 100),
        delta: spendingDelta === null ? null : Math.round(spendingDelta),
        icon: DollarSign,
        loading: isOrgUsageGraphLoadingLast24 || isOrgUsageGraphLoadingFirst24,
      },
      {
        label: "Last 24 hours' usage",
        value: `${hoursLast24} hours`,
        delta: hoursDelta === null ? null : Math.round(hoursDelta),
        icon: Timer,
        loading: isOrgGraphLoadingLast24 || isOrgGraphLoadingFirst24,
      },
      {
        label: "Active Now",
        value: `${orgMetric?.server_instance_count || 0} Servers`,
        delta: null,
        icon: Activity,
        loading: isOrgMetricLoading,
      },
    ];
  }, [
    orgUsageGraphLast24,
    orgGraphLast24,
    orgUsageGraphFirst24,
    orgGraphFirst24,
    orgMetric,
    isOrgUsageGraphLoadingLast24,
    isOrgUsageGraphLoadingFirst24,
    isOrgGraphLoadingLast24,
    isOrgGraphLoadingFirst24,
    isOrgMetricLoading,
  ]);

  const renderDelta = (item: (typeof displayedData)[number]) => {
    if (item.delta) {
      if (item.loading) {
        return <div className="h-4 w-full pulse bg-foreground/10 mt-auto" />;
      }
      return (
        <div className="mb-2 text-sm text-foreground/40">
          {item.delta > 0 ? `+${item.delta}%` : `${item.delta}%`} from day
          before
        </div>
      );
    }
    return null;
  };

  return (
    <div className="mb-12 grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
      {displayedData.map((item, index) => (
        <Card key={index} className="px-6 py-4 h-[120px]">
          <div className="mb-2 text-sm text-foreground/80 w-full justify-between flex items-center gap-4">
            {item.label}{" "}
            <item.icon className="w-4 h-4 stroke-foreground/50" />
          </div>
          {item.loading ? (
            <div className="h-4 w-full pulse bg-foreground/10 mt-auto" />
          ) : (
            <div className="mb-2 text-2xl font-bold">{item.value}</div>
          )}
          {renderDelta(item)}
        </Card>
      ))}
    </div>
  );
};

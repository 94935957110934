import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { toast } from "react-toastify";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Plus } from "lucide-react";
import { useCreateStorageVolume } from "@/services/storage";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";

const FormSchema = z.object({
  name: z.string().min(2, {
    message: "Name must be at least 2 characters.",
  }),
  size: z.coerce.number().min(1, {
    message: "Size must be at least 1GB.",
  }),
});

export const CreateStorageVolume = () => {
  const [open, setOpen] = useState(false);
  const { data } = useActiveOrgProfile();

  const storageCountLimit =
    data?.org.metadata.limits.org_per_user_storage_count || 0;
  const storageSizeLimit =
    data?.org.metadata.limits.org_per_user_storage_gb || 0;

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      size: 0,
    },
  });

  useEffect(() => {
    if (storageSizeLimit) {
      form.reset({
        size: storageSizeLimit,
      });
    }
  }, [storageSizeLimit, form]);

  const { mutateAsync, isPending } = useCreateStorageVolume({
    onSuccess: () => {
      setOpen(false);
      toast.success("Storage Volume created successfully");
    },
    onError: (error: Error) => {
      toast.error(`${error}`);
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    await mutateAsync({
      name: data.name.trim(),
      sizeGb: data.size,
    });

    form.reset();
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="w-4 h-4 mr-2" />
          New Storage Volume
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Storage Volume</DialogTitle>
          <DialogDescription>
            Add a persistent storage volume for mounting in server instances.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="name"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="storage volume name" {...field} />
                  </FormControl>
                  <FormDescription>
                    This is the name of the storage volume.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="size"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Size (GB)</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="1"
                      {...field}
                      min={1}
                      max={1000}
                    />
                  </FormControl>
                  <FormDescription>
                    This is the size of the storage volume (GB).
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Alert variant="warning">
              <InfoCircledIcon className="h-4 w-4" />
              <AlertTitle>Organization Limits:</AlertTitle>
              <AlertDescription>
                {storageCountLimit} active storage volume instances, and a max
                size of {storageSizeLimit}GB per volume.
              </AlertDescription>
            </Alert>

            <DialogFooter>
              <Button disabled={isPending} type="submit">
                {isPending ? "Creating..." : "Create"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/store/UserStore";
import Gravatar from "react-gravatar";
import { useUpdateUserProfile } from "@/services/profile";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";
import { capitalize } from "@/helpers/string";
import { UserPen } from 'lucide-react';

const FormSchema = z.object({
  firstName: z.string().max(50, {
    message: "First name must be less than 50 characters.",
  }),
  lastName: z.string().max(50, {
    message: "Last name must be less than 50 characters.",
  }),
});

export const MyProfileForm = () => {
  const { user } = useUserStore();
  const { data, refetch } = useActiveOrgProfile();
  const avatarUrl = data?.profile?.avatar_preview_url || "";

  const firstName = data?.profile?.first_name || "";
  const lastName = data?.profile?.last_name || "";

  const { mutateAsync, isPending } = useUpdateUserProfile({
    onSuccess: async () => {
      toast.success("Profile updated successfully");
      await refetch();
    },
    onError: () => {
      toast.error("Profile update failed");
    },
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  const [avatar, setAvatar] = useState<File | null>(null);
  const [imgPreview, setImgPreview] = useState<string | null>(null);

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setAvatar(file);
      setImgPreview(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (data?.profile) {
      form.reset({
        firstName: data.profile?.first_name || "",
        lastName: data.profile?.last_name || "",
      });
    }
  }, [data, form]);

  async function onSubmit(formData: z.infer<typeof FormSchema>) {
    const submissionData = new FormData();
    submissionData.append("first_name", capitalize(formData.firstName));
    submissionData.append("last_name", capitalize(formData.lastName));

    if (avatar) {
      submissionData.append("file", avatar);
    }

    await mutateAsync(submissionData);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8"
      >
        <Card className="border-primary/20">
          <CardContent className="px-14 py-12 flex flex-col gap-4">
            <div className="mx-auto mb-8">
              <Label htmlFor="avatar" className="text-sm font-medium">
                Profile Picture
              </Label>
              <div className="relative w-16 h-16 mx-auto">
                {imgPreview ? (
                  <img
                    src={imgPreview}
                    alt="Avatar Preview"
                    className="w-16 h-16 rounded-full object-cover"
                  />
                ) : avatarUrl ? (
                  <img
                    src={avatarUrl}
                    alt="Avatar"
                    className="w-16 h-16 rounded-full object-cover"
                  />
                ) : (
                  <Gravatar
                    email={user?.email}
                    className="w-16 h-16 bg-primary/30 rounded-full"
                  />
                )}
                <div className="absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4">
                  <div className="w-6 h-6 bg-primary rounded-full flex items-center justify-center border border-white shadow">
                    <UserPen className="w-4 h-4 text-white" />
                  </div>
                </div>
                <input
                  id="avatar"
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarChange}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="email" className="text-sm font-medium">
                Email
              </Label>
              <Input
                disabled
                type="email"
                id="email"
                value={user?.email}
                className="w-full"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>First Name</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder={firstName}
                          className="w-full"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Last Name</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder={lastName}
                          className="w-full"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </CardContent>
        </Card>

        <Button type="submit" className="w-[150px] mx-auto">
          {isPending ? "Saving..." : "Save"}
        </Button>
      </form>
    </Form>
  );
};

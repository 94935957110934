import { TabsList, TabsTrigger } from "@/components/ui/tabs"

export const DashboardHomeHeader = () => {
  return (
    <div className="mb-8 flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
      <TabsList>
        <TabsTrigger value="overview">
          Overview
        </TabsTrigger>
        <TabsTrigger value="analytics">Analytics</TabsTrigger>
        <TabsTrigger disabled value="notifications">Notifications</TabsTrigger>
      </TabsList>
    </div>
  )
}



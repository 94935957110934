import { getUserOrgs } from "@/services/auth";
import { useUserStore } from "@/store/UserStore";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { toast } from "react-toastify";

export const useOrgRedirect = () => {
  const token = useUserStore((state) => state.user?.token);
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async () => {
      const userOrgs = await getUserOrgs(token || "");
      const org = userOrgs.organizations[0];

      if (!org.orgId) {
        toast.error("No organizations found for the user");
      }

      // navigate
      navigate({
        to: org.role.role_name === 'Admin' ? "/$orgId/dashboard/home" : "/$orgId/dashboard/servers/new",
        params: {
          orgId: org.orgId.toString(),
        },
      });
    },
  });
};

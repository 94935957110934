import { TrashIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { DialogDescription } from "@radix-ui/react-dialog";
import { Input } from "@/components/ui/input";
import { StorageVolume } from "@/types";
import dayjs from "dayjs";
import { useState } from "react";
import { useDeleteStorageVolume } from "@/services/storage";
import { toast } from "react-toastify";
import { Server } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useElapsedTime } from "@/helpers/time";
import { capitalize } from "@/helpers/string";

type StorageCardProps = StorageVolume & {
  user_profile?: {
    first_name: string;
    last_name: string;
  };
};

export const StorageCard = ({
  id,
  name,
  size_gb,
  created_at,
  user_email,
  status,
  server_instances,
  user_profile
}: StorageCardProps) => {
  const [open, setOpen] = useState(false); // State to control dialog visibility
  const [volumeIdInput, setVolumeIdInput] = useState(""); // State for volume ID input in the dialog

  const uptime = useElapsedTime(created_at);

  const { mutate, isPending } = useDeleteStorageVolume({
    onSuccess: () => {
      setOpen(false);
      toast.success("Volume deleted successfully");
    },
    onError: () => {
      toast.error("Failed to delete volume");
    },
  });

  // Handler for when the delete button is clicked
  const handleDeleteClick = () => {
    // Check if the volume is mounted; if so, show a warning and do not open the dialog
    if (status === "mounted") {
      toast.warning(
        "Cannot delete storage volume while it is mounted on a server, please delete the associated server first",
      );
      return; // Prevent dialog from opening
    }

    // If the volume is not mounted, allow the dialog to open
    setOpen(true);
  };

  // Handler for form submission inside the delete dialog
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Check if the entered volume ID matches the actual volume ID
    if (volumeIdInput.trim() !== name.toString().trim()) {
      toast.error("Volume name did not match");
      return;
    }
    // Trigger the mutation to delete the volume
    mutate(id);
  };

  return (
    <div
      className={`px-6 py-4 rounded-sm shadow-md border-2 ${status === "mounted" ? "border-primary" : "border-border"
        }`}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <h3 className="text-xl font-semibold">{name}</h3>
          {/* Placeholder for EditStorageName component */}
          {/* <EditStorageName /> */}
        </div>

        <span className="text-text font-semibold">{size_gb} GB</span>
      </div>

      <p className="mt-2 text-foreground/80 text-sm font-semibold">
        Total Volume Size: {size_gb}GB
      </p>
      <p className="mt-2 text-foreground/80 text-sm font-medium">
        Volume ID: {id}
      </p>

      <p className="mt-2 text-foreground text-sm">
        Created by{" "}
        <span className="font-bold">
          {user_email}
          {user_profile?.first_name && user_profile?.last_name
            ? ` (${user_profile.first_name} ${user_profile.last_name})`
            : ""}
        </span>{" "}
        on {dayjs(created_at).format("MMMM DD, YYYY")}
      </p>

      <div className="flex w-full items-center justify-between gap-4 mt-3">
        <div className="flex items-center gap-4">
          <ServerMountDetails
            serversMounted={server_instances}
            TriggerButton={
              <Button size="default" variant="outline">
                {capitalize(status)} • {uptime}
              </Button>
            }
          />
        </div>

        {/* Delete button, which triggers the handleDeleteClick function */}
        <Button
          variant="outline"
          className="flex gap-2 items-center"
          onClick={handleDeleteClick}
        >
          <TrashIcon className="w-4 h-4" /> Delete
        </Button>

        {/* Delete confirmation dialog */}
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogContent className="max-w-3xl p-10">
            <DialogHeader className="gap-3">
              <DialogTitle className="flex items-center gap-4 font-semibold">
                Are you sure you want to delete this storage volume?
              </DialogTitle>

              <DialogDescription className="text-destructive">
                This action is destructive and cannot be undone. Associated data
                will be lost.
              </DialogDescription>
            </DialogHeader>

            <div className="w-[70%] p-4 rounded-sm border border-border flex flex-col gap-1 my-4">
              <p className="text-foreground/80 font-semibold">Name: {name}</p>
              <p className="text-foreground/50">ID: {id}</p>
            </div>

            <Separator />

            <form className="space-y-4" onSubmit={onSubmit}>
              <div className="flex flex-col gap-5">
                <p className="text-destructive">
                  Please type the Storage Volume name below to confirm this
                  action:
                </p>

                <Input
                  value={volumeIdInput}
                  onChange={(e) => setVolumeIdInput(e.target.value)}
                  required
                  placeholder="re-enter the volume name to confirm"
                  className="w-full"
                />
              </div>

              <DialogFooter className="w-full items-center justify-end">
                <Button type="submit" disabled={isPending}>
                  {isPending ? "Deleting..." : "Delete"}
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

// EditStorageName component for future use (currently commented out in the StorageCard component)
// const EditStorageName = () => {
//   return (
//     <Dialog>
//       <DialogTrigger asChild>
//         <Button variant="ghost">
//           <PencilLine className="w-4 h-4" />
//         </Button>
//       </DialogTrigger>

//       <DialogContent className="max-w-lg p-8">
//         <DialogHeader className="gap-3">
//           <DialogTitle className="flex items-center gap-4 font-semibold">
//             Name this storage volume
//           </DialogTitle>
//         </DialogHeader>

//         <Input placeholder="Name of your volume" className="w-full" />

//         <DialogFooter className="w-full items-center justify-end">
//           <Button type="submit">Save</Button>
//         </DialogFooter>
//       </DialogContent>
//     </Dialog>
//   );
// };

interface ServerMountDetailsProps {
  TriggerButton: React.ReactNode;
  serversMounted: StorageVolume["server_instances"];
}

export default function ServerMountDetails({
  TriggerButton,
  serversMounted,
}: ServerMountDetailsProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>{TriggerButton}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">
            Storage Status
          </DialogTitle>
          <DialogDescription>
            This storage volume is currently mounted on the following servers:
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="h-[300px] pr-4">
          {serversMounted && serversMounted.length > 0 ? (
            <div className="space-y-4">
              {serversMounted.map((server, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-4 rounded-lg border p-4 transition-colors hover:bg-muted"
                >
                  <Server className="h-6 w-6 flex-shrink-0 text-primary" />
                  <div className="flex-grow">
                    <p className="font-medium">{server.instance_name}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div
                      className={`h-3 w-3 flex-shrink-0 rounded-full bg-primary`}
                    />
                    <span className="text-sm text-muted-foreground">
                      Active
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-muted-foreground">
              No servers currently mounted.
            </p>
          )}
        </ScrollArea>
        <DialogFooter className="sm:justify-between">
          <p className="text-sm text-muted-foreground">
            Total servers: {serversMounted ? serversMounted.length : 0}
          </p>
          <DialogClose asChild>
            <Button variant="secondary">Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

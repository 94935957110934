import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  ChevronRight,
  ChevronLeft,
  Edit2,
  Plus,
  X,
  Microchip,
  Fan,
  Disc3,
  ChevronDown,
} from "lucide-react";
import { useListServerTemplates } from "@/services/template";
import { useCreateServerInstance } from "@/services/server";
import { useListStorageVolumes } from "@/services/storage";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { StorageVolume } from "@/types";
import { useTokenAndOrgId } from "@/services/utils";
import { useNavigate } from "@tanstack/react-router";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";
import { toast } from "react-toastify";
import TemplateCard from "../DashboardTemplates/templateCard";
import { ServerTemplateResponse } from "@/services/template/types";
import { useGetSSHKeys } from "@/services/sshkeys";
import { Spinner } from "@/components/spinner";
import { cn } from "@/lib/utils";
import ConfirmNavigation from "@/components/confirm-navigation";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ServerInstanceRequestData } from "./types";
import { useUserStore } from "@/store/UserStore";

const formSchema = z.object({
  serverName: z
    .string()
    .min(2, {
      message: "Server name must be at least 2 characters.",
    })
    .max(100, {
      message: "Server name cannot exceed 100 characters.",
    }),
  sshAccess: z.boolean(),
  storageVolumeId: z.string().nullable().optional(),
  httpPorts: z.array(z.string().regex(/^\d+$/, "Must be a valid port number")),
});

export const DashboardNewServer = () => {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const { setHasCreatedFirstServer } = useUserStore(s => ({ setHasCreatedFirstServer: s.setHasCreatedFirstServer }));

  const [step, setStep] = useState(0);
  const [selectedTemplate, setSelectedTemplate] =
    useState<ServerTemplateResponse | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newPort, setNewPort] = useState("");
  const { orgId } = useTokenAndOrgId();
  const navigate = useNavigate();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const { data } = useActiveOrgProfile();

  const serverInstanceLimit =
    data?.org?.metadata?.limits?.org_per_user_server_count || 0;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      serverName: "",
      sshAccess: false,
      storageVolumeId: undefined,
      httpPorts: [],
    },
  });

  const {
    data: templates,
    isLoading: isLoadingTemplates,
    isError: isErrorTemplates,
  } = useListServerTemplates();
  const {
    data: volumeData,
    isLoading: isLoadingVolumes,
    isError: isErrorVolumes,
  } = useListStorageVolumes({});
  const { data: sshKeys, isLoading: isLoadingSSHKeys } = useGetSSHKeys();

  // Flatten the volumes from all pages into a single array
  const volumes: StorageVolume[] = volumeData?.pages
    ? volumeData.pages.flatMap((page) => page.volumes)
    : [];

  const handleTemplateSelect = (template: ServerTemplateResponse) => {
    setSelectedTemplate(template);
    form.setValue("serverName", template.template_name);
  };

  useEffect(() => {
    if (!isLoadingTemplates) {
      if (templates && templates.length > 0) {
        setSelectedTemplate(templates[0]);
        form.setValue("serverName", templates[0].template_name);
      }
    }
  }, [templates, isLoadingTemplates, form]);

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleNameChange = () => {
    if (selectedTemplate) {
      const newName = form.getValues("serverName");
      setSelectedTemplate({ ...selectedTemplate, template_name: newName });
      setIsDialogOpen(false);
    }
  };

  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const maxPorts = 3; // Max number of ports

  const handleAddPort = () => {
    if (!selectedTemplate) {
      console.error("selected template is null", { selectedTemplate });
      toast.error("template error");
      return;
    }

    if (!newPort) {
      form.setError("httpPorts", {
        type: "manual",
        message: "Invalid port number.",
      });
      return;
    }

    if (
      selectedTemplate.service_ports
        .map((p) => p.port)
        .includes(Number(newPort))
    ) {
      form.setError("httpPorts", {
        type: "manual",
        message: "This port is already in use by the template.",
      });
      return;
    }

    const currentPorts = form.getValues("httpPorts");

    const portNumber = parseInt(newPort, 10);
    if (isNaN(portNumber) || portNumber < 1000 || portNumber > 99999) {
      form.setError("httpPorts", {
        type: "manual",
        message: "Port must be a number between 1000 and 99999.",
      });
      return;
    }

    if (currentPorts.includes(newPort)) {
      form.setError("httpPorts", {
        type: "manual",
        message: "This port has already been added.",
      });
      return;
    }

    if (currentPorts.length >= maxPorts) {
      form.setError("httpPorts", {
        type: "manual",
        message: `You can only add up to ${maxPorts} ports.`,
      });
      return;
    }

    form.setValue("httpPorts", [...currentPorts, newPort]);
    setNewPort("");
    setIsInputDisabled(currentPorts.length + 1 === maxPorts);
    setIsAddButtonDisabled(currentPorts.length + 1 === maxPorts);
    form.clearErrors("httpPorts");
  };

  const handlePortInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (/^\d{0,5}$/.test(value)) {
      setNewPort(value);
      if (value) {
        setIsAddButtonDisabled(false);
      }
    }
  };

  const handleRemovePort = (port: string) => {
    const updatedPorts = form
      .getValues("httpPorts")
      .filter((p: string) => p !== port);

    form.setValue("httpPorts", updatedPorts);

    if (updatedPorts.length < maxPorts) {
      setIsInputDisabled(false);
      setIsAddButtonDisabled(false);
    }
  };

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const createServerMutation = useCreateServerInstance();
  const handleServerCreation = (values: z.infer<typeof formSchema>) => {
    if (!selectedTemplate || !orgId) {
      return;
    }

    const requestData: ServerInstanceRequestData = {
      instanceName: values.serverName,
      templateId: selectedTemplate.template_id,
      orgId,
      allowSSH: values.sshAccess,
      extraPorts: values.httpPorts.map(Number),
    };

    if (values.storageVolumeId) {
      requestData.storageVolumeId = parseInt(values.storageVolumeId, 10);
    }

    createServerMutation.mutate(requestData, {
      onSuccess: () => {
        setHasCreatedFirstServer();
        navigate({ to: `/${orgId}/dashboard/servers` });
        setErrorMessage(null);
      },
      onError: (error: unknown) => {
        toast.error(`${error}`);
      },
    });
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    const trimmedValues = {
      ...values,
      serverName: values.serverName.trim(),
    };

    handleServerCreation(trimmedValues);
  };

  const handleDeployClick = () => {
    setIsConfirmationDialogOpen(true);
  };
  const handleConfirmDeploy = () => {
    setIsConfirmationDialogOpen(false);
    form.handleSubmit(onSubmit)(); // Proceed with form submission
  };
  const handleCancelDeploy = () => {
    setIsConfirmationDialogOpen(false);
  };

  return (
    <Form {...form}>
      <form className="w-full mx-auto">
        {errorMessage && (
          <div className="text-destructive/60 mb-4">{errorMessage}</div>
        )}
        {step === 0 && (
          <div>
            <h1 className="text-3xl font-medium mb-6">Choose A Template</h1>

            {isLoadingTemplates ? (
              <Spinner />
            ) : isErrorTemplates ? (
              <p>Failed to load templates. Please try again.</p>
            ) : templates && templates?.length > 0 ? (
              <div className="grid gap-6">
                {templates.map((template, index: number) => (
                  <TemplateCard
                    key={template.template_id}
                    index={String(index)}
                    name={template.template_name}
                    cpuRequestCount={Number(template.cpu_count)}
                    ramRequestGb={Number(template.ram_gb)}
                    gpuRequestCount={Number(template.gpu_request_count)}
                    gpuRequestGmem={Number(template.gpu_request_gmem)}
                    machineImage={template.machine_image}
                    price={1.99}
                    selected={selectedTemplate === template}
                    onSelect={() => handleTemplateSelect(template)}
                  />
                ))}
              </div>
            ) : (
              <p>No templates available</p>
            )}
          </div>
        )}

        {step === 1 && selectedTemplate && (
          <div>
            <h1 className="text-3xl font-medum mb-6">Edit Configurations</h1>
            <Card>
              <CardHeader>
                <CardTitle className="flex justify-between items-center">
                  <span className="flex items-center text-2xl">
                    {selectedTemplate.template_name}
                    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                      <DialogTrigger asChild>
                        <Button variant="ghost" size="icon" className="ml-2">
                          <Edit2 className="h-4 w-4" />
                          <span className="sr-only">Edit Server Name</span>
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Edit Server Name</DialogTitle>
                        </DialogHeader>
                        <div className="space-y-4 py-4">
                          <FormField
                            control={form.control}
                            name="serverName"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>
                                  New Server Name{" "}
                                  <span className="text-primary/50">
                                    (Server names have a maximum length of 50
                                    characters)
                                  </span>
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    placeholder="Enter new Server name"
                                    maxLength={50}
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <div className="flex justify-end">
                            <Button
                              onClick={handleNameChange}
                              disabled={!form.getValues("serverName")}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </span>
                </CardTitle>
              </CardHeader>

              <CardContent className="space-y-4">
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Microchip className="h-5 w-5 text-muted-foreground" />
                    <span className="text-sm">
                      {selectedTemplate.cpu_count} vCPU,{" "}
                      {selectedTemplate.ram_gb}GB RAM
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Fan className="h-5 w-5 text-primary" />
                    <span className="text-sm">
                      GPU: {selectedTemplate.gpu_request_count}x{" "}
                      {selectedTemplate.gpu_request_gmem}GB VRAM
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Disc3 className="h-5 w-5 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground">
                      Machine Image: {selectedTemplate.machine_image}
                    </span>
                  </div>
                </div>

                <p
                  className="text-sm font-medium bg-muted px-3 py-2 rounded cursor-pointer flex items-center gap-2"
                  onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                >
                  Advanced options (optional){" "}
                  <ChevronDown className="w-4 h-4" />
                </p>

                <div className={cn(showAdvancedOptions ? "" : "hidden")}>
                  <FormField
                    control={form.control}
                    name="sshAccess"
                    render={({ field }) => (
                      <FormInput label="Allow SSH Access">
                        <>
                          {isLoadingSSHKeys ? (
                            <p>Loading SSH Keys...</p>
                          ) : sshKeys && sshKeys.length > 0 ? (
                            <FormControl>
                              <Switch
                                disabled={false} // Enable switch if SSH keys are available
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          ) : (
                            <p>No SSH Keys Available</p>
                          )}

                          <ConfirmNavigation
                            onConfirm={() =>
                              navigate({ to: `/${orgId}/dashboard/sshkeys` })
                            }
                            trigger={
                              <Button
                                type="button"
                                size="sm"
                                variant="link"
                                className="underline decoration-wavy"
                              >
                                Setup SSH Keys
                              </Button>
                            }
                          />
                        </>
                      </FormInput>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="storageVolumeId"
                    render={({ field }) => (
                      <FormInput label="Attach Storage Volume">
                        {isLoadingVolumes ? (
                          <p>Loading volumes...</p>
                        ) : (
                          <>
                            {volumes && volumes.length > 0 ? (
                              <FormControl>
                                <Select
                                  onValueChange={field.onChange}
                                  value={field.value || undefined}
                                >
                                  <SelectTrigger className="max-w-sm">
                                    <SelectValue placeholder="Select Storage Volume" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    {volumes.map((volume: StorageVolume) => (
                                      <SelectItem
                                        key={volume.id}
                                        value={volume.id.toString()}
                                      >
                                        {volume.name} - {volume.size_gb} GB
                                      </SelectItem>
                                    ))}
                                  </SelectContent>
                                </Select>
                              </FormControl>
                            ) : (
                              <p>No volumes available</p>
                            )}
                          </>
                        )}
                        {isErrorVolumes && <p>Failed to load volumes.</p>}

                        <ConfirmNavigation
                          onConfirm={() =>
                            navigate({ to: `/${orgId}/dashboard/storage` })
                          }
                          trigger={
                            <Button
                              type="button"
                              size="sm"
                              variant="link"
                              className="underline decoration-wavy"
                            >
                              Setup Storage
                            </Button>
                          }
                        />
                      </FormInput>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="httpPorts"
                    render={() => (
                      <FormInput label="HTTP Ports">
                        <div className="flex flex-col">
                          <FormControl>
                            <div className="flex items-center space-x-2 w-60">
                              <Input
                                value={newPort}
                                onChange={handlePortInputChange}
                                placeholder="Port Number"
                                disabled={isInputDisabled}
                              />
                              <Button
                                type="button"
                                variant="default"
                                size="icon"
                                onClick={handleAddPort}
                                className="h-8 w-10 rounded-full"
                                disabled={isAddButtonDisabled}
                              >
                                <Plus className="h-4 w-4" />
                              </Button>
                            </div>
                          </FormControl>

                          <div className="flex flex-wrap gap-2 mt-2">
                            {selectedTemplate.service_ports &&
                              Array.isArray(selectedTemplate.service_ports) &&
                              selectedTemplate.service_ports.map(
                                (port, index) =>
                                  index === 0 ? (
                                    <TooltipProvider key={port.port}>
                                      <Tooltip>
                                        <TooltipTrigger asChild>
                                          <div className="flex items-center bg-secondary text-secondary-foreground rounded-full px-3 py-1">
                                            <span>{port.port}</span>
                                          </div>
                                        </TooltipTrigger>
                                        <TooltipContent
                                          side="top"
                                          align="center"
                                        >
                                          <p>
                                            Port {port.port} is occupied in this
                                            template by default.
                                          </p>
                                        </TooltipContent>
                                      </Tooltip>
                                    </TooltipProvider>
                                  ) : (
                                    <div
                                      key={port.port}
                                      className="flex items-center bg-secondary text-secondary-foreground rounded-full px-3 py-1"
                                    >
                                      <span>{port.port}</span>
                                    </div>
                                  ),
                              )}

                            {form.watch("httpPorts").map((port) => (
                              <div
                                key={port}
                                className="flex items-center bg-primary text-primary-foreground rounded-full px-3 py-1"
                              >
                                <span>{port}</span>
                                <Button
                                  type="button"
                                  variant="ghost"
                                  size="icon"
                                  className="h-4 w-4 ml-2"
                                  onClick={() => handleRemovePort(port)}
                                >
                                  <X className="h-3 w-3" />
                                </Button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </FormInput>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        )}

        {form.formState.errors.serverName && (
          <p className="text-destructive ml-4 pt-5">
            {form.formState.errors.serverName.message}
          </p>
        )}

        <div className="flex justify-between mt-6 items-center">
          {step > 0 && (
            <Button type="button" onClick={handleBack} variant="outline">
              <ChevronLeft className="mr-2 h-4 w-4" /> Back
            </Button>
          )}
          {step === 0 && <div />}
          {step === 0 ? (
            <Button
              type="button"
              onClick={() => setStep(1)}
              disabled={!selectedTemplate}
            >
              Next <ChevronRight className="ml-2 h-4 w-4" />
            </Button>
          ) : (
            <div className="flex items-center space-x-2">
              <Button type="button" onClick={handleDeployClick}>
                Deploy
              </Button>
            </div>
          )}
        </div>

        {/* Confirmation Dialog */}
        <Dialog
          open={isConfirmationDialogOpen}
          onOpenChange={setIsConfirmationDialogOpen}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Confirm Server Deployment</DialogTitle>
            </DialogHeader>
            <div className="py-4">
              <p>
                Are you sure you want to deploy this server with the selected
                configurations?
              </p>
            </div>
            <Alert variant="warning">
              <InfoCircledIcon className="h-4 w-4" />
              <AlertTitle>Organization Limits:</AlertTitle>
              <AlertDescription>
                {serverInstanceLimit} active server instances
              </AlertDescription>
            </Alert>
            <DialogFooter>
              <Button variant="ghost" onClick={handleCancelDeploy}>
                Cancel
              </Button>
              <Button onClick={handleConfirmDeploy}>Confirm</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </form>
    </Form>
  );
};

type FormInputProps = {
  label: string;
  children: React.ReactNode;
};

const FormInput = ({ label, children }: FormInputProps) => {
  return (
    <FormItem className="w-full grid grid-cols-1 grid-rows-[auto_1fr] lg:grid-cols-[minmax(100px,500px)_1fr] lg:grid-rows-1">
      <FormLabel className="whitespace-nowrap pt-4">{label}</FormLabel>

      <div className="flex flex-col gap-2">
        <div className="flex items-center">{children}</div>

        <FormMessage />
      </div>
    </FormItem>
  );
};

import { DashboardAnalyticsTab } from "./analytics-tab"
import { DashboardHomeHeader } from "./header"
import { DashboardOverviewTab } from "./overview-tab"
import { Tabs, TabsContent } from "@/components/ui/tabs"

export const DashboardHome = () => {
  return (
    <Tabs defaultValue="overview" className="w-full sm:w-auto">
      <DashboardHomeHeader />

      <TabsContent value="overview">
        <DashboardOverviewTab />
      </TabsContent>
      <TabsContent value="analytics">
        <DashboardAnalyticsTab />
      </TabsContent>
      <TabsContent value="notifications">
        <div>Notifications</div>
      </TabsContent>
    </Tabs>
  )
}
